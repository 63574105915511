<template>
    <div class="quiz">

        <h1 v-if="quiz">{{ quiz.name }}</h1>

        <div class="message-wrapper" v-if="message != null">
            <div class="message">
                <div v-if="message == 'error'">
                    <div class="error">Niepoprawna odpowiedź</div>
                    <button @click="closeMessage()">Kliknij i spróbuj ponownie</button>
                </div>

                <div v-if="message == 'success'">
                    <div class="success">Dziękujemy za udział w Quizie</div>
                    <router-link to="/dashboard" tag="button">Wróć do listy</router-link>
                </div>
            </div>
        </div>

        <div class="questions" v-if="message == null">
            <div class="question" v-for="(question, ind) in questions"  :key="question.id">
                <div v-if="currentQuestion == ind">

                    <h3>
                        <strong>{{ ind+1 }}. </strong>
                        <span v-html="question.question"></span>
                    </h3>

                    <div class="answers">
                        <div class="item" @click="userAnswer(ind, index)" v-for="(answer, index) in question.answers" :key="index">
                            {{ answer.answer }}
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

    import { model } from "../model.js";

    export default {
        name: 'Quiz',
        data() {
            return {
                'message': null,
                'currentQuestion': null,
                'quiz': null,
                'questions': null,
                'answers': {}
            }
        },
        mounted() {
            this.init();
        },
        methods: {

            closeMessage: function()
            {
                this.message = null;
            },

            back: function()
            {

            },

            userAnswer: function(qindex, aindex)
            {
                var tmp = this.questions[qindex].answers[aindex];

                if (tmp.isCorrect)
                {
                    this.answers[this.currentQuestion] = aindex;

                    this.currentQuestion = qindex+1;

                    if (this.currentQuestion == this.questions.length)
                    {
                        // Zapisywanie quizu
                        var id = this.$route.params.id;
                        model.saveQuiz(id, {"answers": this.answers});

                        this.message = 'success';
                    }
                }
                else
                {

                    model.incorrectAnswer(this.$route.params.id);
                    this.message = 'error';
                }
            },

            init: async function() {

                var id = this.$route.params.id;

                var response = await model.getQuiz(id);

                if (response.data.status == 'Error')
                {
                    localStorage.removeItem('token');
                    this.$router.push({ name: 'Home' });
                    setTimeout(function() {
                        location.reload();
                    }, 100);
                }

                this.quiz = response.data.results;
                this.questions = this.quiz.questions;

                this.currentQuestion = 0;


            }

        }
    }
</script>
